import Image from "next/image"
import { useRouter } from "next/router"
import React, { useState } from "react"

import {
  imageLayouts,
  imageLoadings,
  imageObjectFits,
} from "../../../utils/constants/imageAttributes"
import { RU } from "../../../utils/constants/locales"
import { viewTypes } from "../../../utils/constants/viewTypes"

import styles from "../commonCard.module.scss"
import { layoutLang } from "../../../utils/language/layout"
import { SwiperSlide, Swiper } from "swiper/react"
import { Pagination } from "swiper"

import "swiper/css"
import "swiper/css/pagination"
import DocumentsIcon from "assets/icons/DocumentsIcon"
import BuildingIcon from "assets/icons/BuildingIcon"

type Props = {
  viewType: viewTypes
  images: {
    small: string
    medium: string
    large: string
  }[]
  classes: any
  alt: string
  is_top?: boolean
}

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#fff" offset="20%" />
      <stop stop-color="#ccc" offset="50%" />
      <stop stop-color="#fff" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#fff" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str)

const CommonCardTop: React.FC<Props> = ({
  images,
  viewType,
  classes,
  alt,
  is_top,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const { locale } = useRouter()
  // handle mouse enter
  const handleMouseEnterSlider = (index: number) => {
    setActiveImageIndex(index)
  }
  // handle mouse leave
  const handleMouseLeave = () => {
    setActiveImageIndex(0)
  }

  // top tags container
  const topTagsCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.tags}>
          {is_top ? (
            <div className={`${styles.tag} ${styles.tag_primary}`}>
              <span>{layoutLang[String(locale)]?.TOP}</span>
            </div>
          ) : (
            ""
          )}
          {classes ? (
            <div className={styles.tag}>
              <span>
                {locale === RU ? classes?.name?.ru : classes?.name?.uz}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      )
    }
    return null
  }

  // image slider container
  const imageSliderCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.image_slider}>
          {images?.slice(0, 4)?.map((_, index) => (
            <div
              onMouseEnter={() => handleMouseEnterSlider(index)}
              key={index}
            />
          ))}
        </div>
      )
    }
    return null
  }

  // slider bullets container
  const sliderBulletsCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.slider_bullets}>
          {images?.slice(0, 4)?.map((_, index) => (
            <span
              className={index === activeImageIndex ? styles.active : ""}
              key={index}
            />
          ))}
        </div>
      )
    }
    return null
  }

  // count of images
  const countOfImages = (index: number) => {
    if (viewType === viewTypes.LIST) {
      if (index === 3 && images?.length > 4) {
        return (
          <div className={styles.count_of_images}>
            <p>+{images?.length - 4}</p>
          </div>
        )
      }
    }
    return null
  }


  return (
    <div className={styles.complex_card_top} onMouseLeave={handleMouseLeave}>
      <div className={styles.container}>
        {topTagsCont()}
        <div className={styles.image_cont}>
          {images?.slice(0, 4)?.map((item, index) => (
            <div
              className={`${index === activeImageIndex ? styles.active : ""} ${
                styles.image
              } ${styles[`image${index}`]}`}
              key={index}
            >
              <Image
                src={
                  activeImageIndex === index
                    ? item.medium
                    : images[activeImageIndex].medium
                }
                layout={imageLayouts.FILL}
                objectFit={imageObjectFits.COVER}
                loading={imageLoadings.LAZY}
                decoding="async"
                alt={alt}
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  shimmer(700, 475)
                )}`}
              />
              {countOfImages(index)}
            </div>
          ))}
        </div>
        {imageSliderCont()}
        {sliderBulletsCont()}
      </div>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className={styles.mySwiper}
      >
        {images?.slice(0, 8)?.map((item, index) => (
          <SwiperSlide key={index}>
            <Image
              src={item?.medium}
              layout={imageLayouts.FILL}
              objectFit={imageObjectFits.COVER}
              loading={imageLoadings.LAZY}
              decoding="async"
              alt={alt}
              placeholder="blur"
              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                shimmer(700, 475)
              )}`}
            />
            <div className={styles.mySwiper_card}></div>
            {countOfImages(index)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CommonCardTop
