import { isEmptyObject } from "../../../utils/helpers/isEmptyObject"
import { stringify } from "query-string"
import { SIZE } from "../../../utils/constants/queryParamsKeys"
import { ParsedUrlQuery } from "querystring"
import $api, { $newapi } from "../../../services/RequestService"
import { isNumberOdd } from "utils/helpers/isNumberOdd"

// apartments
export const fetchApartments = ({
  query,
  complex_id,
}: {
  query: ParsedUrlQuery
  complex_id?: number
}) => {
  let url = `/apartment/view`

  if (complex_id) {
    if (!isEmptyObject(query)) {
      url += `?${stringify(
        {
          ...query,
          [SIZE]: !query?.page || isNumberOdd(Number(query?.page)) ? 14 : 15,
          complex_id: complex_id,
        },
        { arrayFormat: "comma" }
      )}`
    } else {
      url += `?${stringify(
        { complex_id: complex_id },
        { arrayFormat: "comma" }
      )}`
    }
  } else if (!isEmptyObject(query)) {
    url += `?${stringify(
      {
        ...query,
        [SIZE]: 14,
      },
      { arrayFormat: "comma" }
    )}`
  }

  return {
    fetch: async () => {
      const res = await $newapi.get(url)
      return res.data.data
    },
    url,
  }
}

//payment-methods
export const fetchNewPaymentMethods = async () => {
  const res = await $newapi.get(`/payment-method/select`)
  return res.data.data
}
// export const fetchAllRegions = async () =>  {
//   const res = await $newapi.get(`/district/filter?search=${search}`)
//   return res.data.data
// }
