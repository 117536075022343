import React from "react"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"

import HeaderBottom from "./bottom/HeaderBottom"
import AppSuggestion from "../../../../pages/app-suggestion/Index"

import styles from "./header.module.scss"
import BackComponent from "./back-component/BackComponent"

const Header: React.FC = () => {
  // const { md } = Grid.useBreakpoint()

  return (
    <div className={styles.header}>
      <NonSSRWrapper>
        <AppSuggestion />
      </NonSSRWrapper>
      <HeaderBottom />
      {/* <BackComponent /> */}
    </div>
  )
}

export default Header
