import { Button, Tooltip } from "antd"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import Link from "next/link"
import React from "react"
import ReactGA from "react-ga4"

import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import ComplexHeart from "../../../pages/heart/components/complex/ComplexHeart"
import { userReducerActions } from "../../../store/reducers/userReducer"
import { gaActions, gaCategories } from "../../../utils/constants/ga"
import { RU, UZS } from "../../../utils/constants/locales"
import { rootPaths } from "../../../utils/constants/rootPaths"
import { viewTypes } from "../../../utils/constants/viewTypes"
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr"
import { makeUrl } from "../../../utils/helpers/makeUrl"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import NonSSRWrapper from "../../non-ssr/NonSSRWrapper"
import CommonCardTop from "../top/CommonCardTop"

import styles from "../commonCard.module.scss"

import { useRouter } from "next/router"

import PhoneIcon from "../../../assets/icons/PhoneIcon"
import Placements from "../../../pages/detailed-residential-complex/components/placements/Placements"
import { calcUsdValue } from "../../../utils/helpers/calcUsdValue"
import { layoutLang } from "../../../utils/language/layout"

import BadgeIcon from "assets/icons/BadgeIcon"
import BuildingIcon from "assets/icons/BuildingIcon"
import DocumentsIcon from "assets/icons/DocumentsIcon"

dayjs.extend(advancedFormat)

type Props = {
  viewType: viewTypes
  data: NewResidentalComplexModel
}

const ComplexCard: React.FC<Props> = ({ data, viewType }) => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { setCallBackModal } = userReducerActions
  const { currency, usdValue } = useAppSelector((state) => state.localeReducer)

  // price cont
  const priceCont = () => {
    const totalPrice = data?.price_permission ? data?.min_total_price : null

    // checking
    if (!totalPrice) {
      return (
        <div className={styles.price_cont}>
          <p>{layoutLang[String(locale)]["Договорная"]}</p>
        </div>
      )
    }

    return (
      <div className={styles.price_cont}>
        <p>
          {totalPrice
            ? locale === "uz"
              ? `${Math.ceil(
                  calcUsdValue(totalPrice, usdValue, currency)
                )?.toLocaleString(RU)} ${
                  currency === UZS ? "so'mdan" : "$ dan"
                }`
              : `от ${Math.ceil(
                  calcUsdValue(totalPrice, usdValue, currency)
                )?.toLocaleString(RU)} ${currency === UZS ? "сум" : "$"}`
            : layoutLang[String(locale)]["Договорная"]}
        </p>
      </div>
    )
  }

  // coll back
  const handleCallBack = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    dispatch(
      setCallBackModal({
        visible: true,
        type: "complex",
        id: data?.id,
      })
    )
    ReactGA.event({
      action: gaActions.CALL_ME,
      category: gaCategories.PHONE_NUMBER,
    })
  }

  return (
    <Link
      href={`/${data?.district?.city?.url}${
        rootPaths.RESIDENTIAL_COMPLEXES
      }/${makeUrl(data?.name || data?.name)}-${data?.id}`}
      legacyBehavior
    >
      <a className={`${styles.complex_card} ${styles[viewType]}`}>
        <CommonCardTop
          images={data?.image}
          classes={data?.class}
          viewType={viewType}
          is_top={data?.is_top}
          alt={`${layoutLang[String(locale)]["ЖК"]} "${
            data?.name || data?.name
          }"`}
        />
        <div className={`${styles.complex_card_bottom} relative`}>
          <div className="w-auto absolute top-[-48px] z-[1] left-3 flex items-center gap-1.5">
            {data?.availability_construction_document && (
              <Tooltip
                overlay={layoutLang[String(locale)]["qurulish_hujjatlari"]}
              >
                <span className="w-8 h-8 rounded-full flex items-center justify-center bg-[#00000080]">
                  <Link
                    href={`/${data?.district?.city?.url}${
                      rootPaths.RESIDENTIAL_COMPLEXES
                    }/${makeUrl(data?.name || data?.name)}-${
                      data?.id
                    }#documents`}
                    legacyBehavior
                  >
                    <a className="w-full h-full flex items-center justify-center">
                      <DocumentsIcon />
                    </a>
                  </Link>
                </span>
              </Tooltip>
            )}
            {data?.availability_construction_process && (
              <Tooltip
                overlay={layoutLang[String(locale)]["qurulish_jarayoni"]}
              >
                <span className="w-8 h-8 rounded-full flex items-center justify-center bg-[#00000080]">
                  <Link
                    href={`/${data?.district?.city?.url}${
                      rootPaths.RESIDENTIAL_COMPLEXES
                    }/${makeUrl(data?.name || data?.name)}-${
                      data?.id
                    }#proccess`}
                    legacyBehavior
                  >
                    <a className="w-full h-full flex items-center justify-center">
                      <BuildingIcon />
                    </a>
                  </Link>
                </span>
              </Tooltip>
            )}
          </div>
          {(data?.bonuses || !isEmptyArr(data?.payment_methods)) && (
            <div className={styles.top}>
              <div className={styles.tags}>
                {data?.bonuses && (
                  <div className={styles.tag}>
                    <span>{data?.bonuses}</span>
                  </div>
                )}
                {data?.payment_methods?.map((item, index) => (
                  <div className={styles.tag} key={index}>
                    <span>
                      {locale === RU ? item?.name?.ru : item?.name?.ru}
                    </span>
                  </div>
                ))}
              </div>
              <div className={styles.like}>
                <NonSSRWrapper>
                  <ComplexHeart data={data} />
                </NonSSRWrapper>
              </div>
            </div>
          )}
          <div className={styles.name}>
            <h3>
              {`${locale === "ru" ? layoutLang[String(locale)]["ЖК"] : ""} "${
                data?.name
              }" ${locale === "uz" ? layoutLang[String(locale)]["ЖК"] : ""}`}
            </h3>
            {!data?.bonuses && isEmptyArr(data?.payment_methods) && (
              <div className={styles.like}>
                <NonSSRWrapper>
                  <ComplexHeart data={data} />
                </NonSSRWrapper>
              </div>
            )}
          </div>
          <p className={styles.address}>
            {locale === RU
              ? data?.district?.city?.name?.ru
              : data?.district?.city?.name?.uz}
            {", "}
            {locale === RU
              ? data?.district?.name?.ru
              : data?.district?.name?.uz}
          </p>
          <Placements type="complex" data={data?.nearest_places} />
          {priceCont()}
          <Link
            href={`${rootPaths.BUILDERS}/${makeUrl(
              String(data?.builder?.name)
            )}-${data?.builder?.id}`}
            className="flex items-center mb-4 gap-2 !mt-1"
          >
            <p className={`${styles.builder} !mb-0 !text-[#1570EF]`}>
              {data?.company?.brand_name
                ? data?.company?.brand_name
                : data?.builder?.name}
            </p>
            {data?.company?.badge ? (
              <Tooltip overlay={layoutLang[String(locale)]["badge_text"]}>
                <span>
                  <BadgeIcon />
                </span>
              </Tooltip>
            ) : (
              ""
            )}
          </Link>
          <div className={styles.buttons_cont}>
            <Button className={styles.call} onClick={handleCallBack}>
              <PhoneIcon />
              {layoutLang[String(locale)]["Перезвоните мне"]}
            </Button>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default ComplexCard
