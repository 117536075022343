import { useEffect, useRef } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Popover, Spin } from "antd"
import { useTranslations } from "next-intl"
import { useAppDispatch } from "../../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../../store/reducers/userReducer"
import { eventTypes } from "../../../../../utils/constants/eventTypes"
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks"
import { localStorageKeys } from "../../../../../utils/constants/localStorageKeys"
import { isBrowser } from "../../../../../utils/helpers/isBrowser"
import { useLogout } from "../../../services/mutations"
import ReactInputMask from "react-input-mask"
import LogoutIcon from "../../../assets/icon/LogoutIcon"
import NonSSRWrapper from "../../../../../common/non-ssr/NonSSRWrapper"
import HeaderDropDown from "../header-dropdown/HeaderDropDown"

import style from "./HeaderTop.module.scss"
import UserIcon from "assets/icons/UserIcon"
import { layoutLang } from "utils/language/layout"
import { useRouter } from "next/router"
import HeartIcon from "assets/icons/HeartIcon"
import FavouriteIcon from "assets/icons/FavouriteIcon"
import Link from "next/link"

function HeaderTop() {
  const { locale } = useRouter()
  const t = useTranslations("header.headerTop")
  const logout = useLogout()
  const dispatch = useAppDispatch()
  const { setVisibleLoginModal } = userReducerActions
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null)

  const isAuth = isBrowser() && localStorage.getItem(localStorageKeys.IS_AUTH)
  const phone = isBrowser() && localStorage.getItem("phone")

  // open login modal
  const openLoginModal = () => {
    dispatch(setVisibleLoginModal(true))
  }

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click()
  }

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress)

    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress)
    }
  }, [])

  // logout
  const handleLogout = () => {
    logout.mutate()
  }

  return (
    <div className={style.header_right}>
      <div className="flex items-center gap-4">
        <HeaderDropDown type="currency" />
        <HeaderDropDown type="lang" />
      </div>
      <NonSSRWrapper>
        <>
          {isAuth === "true" ? (
            <div className={style.header_right_login}>
              <ReactInputMask
                mask={PHONE_MASK}
                value={localStorage.getItem(localStorageKeys.PHONE) ?? ""}
                disabled
              />
              <Popover
                trigger="click"
                overlayClassName={style.popover}
                content={
                  <div className={style.popover_content}>
                    <Link
                      href="/favourites"
                      className="flex items-center gap-2"
                    >
                      <FavouriteIcon />
                      <p>{layoutLang[String(locale)]["Избранное"]}</p>
                    </Link>
                    <p onClick={handleLogout} aria-hidden>
                      {logout.isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : (
                        <LogoutIcon />
                      )}
                      <p>{t("Выйти")}</p>
                    </p>
                  </div>
                }
              >
                <div className="ml-4 select-none flex items-center gap-2.5 border border-solid border-[#EAECF0] rounded-2xl h-[47px] px-4 cursor-pointer">
                  <p>{phone}</p>
                </div>
              </Popover>
            </div>
          ) : (
            <div
              className={`${style.header_right_login} h-[46px] px-6 border border-solid border-[#0DC683] bg-[#0DC68321] text-[#0DC683] text-lg font-semibold rounded-2xl ml-4`}
              onClick={openLoginModal}
            >
              {layoutLang[String(locale)]["login"]}
            </div>
          )}
        </>
      </NonSSRWrapper>
    </div>
  )
}

export default HeaderTop
