import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DistrictType } from "../../pages/main-layout/utils/models/districtModel"
import { localStorageKeys } from "../../utils/constants/localStorageKeys"
import { sliceNames } from "../../utils/constants/sliceNames"
import { isBrowser } from "../../utils/helpers/isBrowser"

type InitialState = {
  visibleLoginModal: boolean
  visibleMobileMenu: boolean
  visibleAddressModal: boolean
  callBackModal: {
    visible: boolean
    type: string | null
    id: number | null
  }
  userAddress: (DistrictType & { saved?: boolean } & { cityId?: number }) | null
  isAuth: boolean
  menuIcon: boolean
  isOpen: boolean
  isClose: boolean
  constructionModal: {
    visible: boolean
    id?: number,
    data?:{
      images?:{
        small:string;
        medium:string;
        large:string;
      }[];
      name?:string
    }
  }
  activeSlide: number
  openLang: boolean
  openCurrency: boolean
}

const initialState: InitialState = {
  visibleLoginModal: false,
  visibleMobileMenu: false,
  visibleAddressModal: false,
  callBackModal: {
    visible: false,
    type: null,
    id: null,
  },
  userAddress: isBrowser()
    ? JSON.parse(localStorage.getItem(localStorageKeys.ADDRESS) as string) || {
        url_name: "gorod-tashkent",
      }
    : { url_name: "gorod-tashkent" },
  isAuth: isBrowser()
    ? !!localStorage.getItem(localStorageKeys.IS_AUTH)?.replace(/\s/g, "")
    : false,
  menuIcon: false,
  isOpen: true,
  isClose: true,
  constructionModal: {
    id: -1,
    visible: false,
  },
  activeSlide: 0,
  openLang: false,
  openCurrency: false,
}

const userReducer = createSlice({
  name: sliceNames.SMTH,
  initialState,
  reducers: {
    setVisibleLoginModal: (state, action: PayloadAction<boolean>) => {
      state.visibleLoginModal = action.payload
    },
    setVisibleMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.visibleMobileMenu = action.payload
    },
    toggleVisibleMobileMenu: (state) => {
      state.visibleMobileMenu = !state.visibleMobileMenu
    },
    setVisibleAddressModal: (state, action: PayloadAction<boolean>) => {
      state.visibleAddressModal = action.payload
    },
    setCallBackModal: (
      state,
      action: PayloadAction<{
        visible: boolean
        type: string | null
        id: number | null
      }>
    ) => {
      state.callBackModal = action.payload
    },
    setUserAddress: (
      state,
      action: PayloadAction<
        (DistrictType & { saved?: boolean } & { cityId?: number }) | null
      >
    ) => {
      state.userAddress = action.payload
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        localStorage.setItem(localStorageKeys.IS_AUTH, "true")
      } else {
        localStorage.removeItem(localStorageKeys.IS_AUTH)
        localStorage.removeItem(localStorageKeys.PHONE)
      }
      state.isAuth = action.payload
    },
    setChangeMenu: (state, action: PayloadAction<boolean>) => {
      state.menuIcon = action.payload
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setIsClose: (state, action: PayloadAction<boolean>) => {
      state.isClose = action.payload
    },
    setConstructionModal: (
      state,
      action: PayloadAction<{ visible: boolean; id?: number;data?:{
          images?:{
            small:string;
            medium:string;
            large:string
          }[];
          name?:string
        } }>
    ) => {
      state.constructionModal = action.payload
    },
    setActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlide = action.payload
    },
    setOpenLang: (state, action: PayloadAction<boolean>) => {
      state.openLang = action.payload
    },
    setOpenCurrency: (state, action: PayloadAction<boolean>) => {
      state.openCurrency = action.payload
    },
  },
})

export default userReducer.reducer
export const userReducerActions = userReducer.actions
