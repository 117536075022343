import { LOCATION } from "../../../../utils/constants/queryParamsKeys"
import { rootPaths } from "../../../../utils/constants/rootPaths"

export const menuLinks = (
  location: string | undefined,
  city_id: number | undefined,
  district_id: number | undefined
) => {
  return [
    {
      label: "Каталог ЖК",
      path:
        city_id && district_id
          ? `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}?city_id=${city_id}&district_id=${district_id}`
          : city_id
          ? `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}?city_id=${city_id}`
          : district_id
          ? `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}?district_id=${district_id}`
          : `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
      basePath: `/[${LOCATION}]${rootPaths.RESIDENTIAL_COMPLEXES}`,
    },
    {
      label: "Карта новостроек",
      path: rootPaths.MAP,
      basePath: rootPaths.MAP,
    },
    {
      label: "Квартиры",
      path:
        city_id && district_id
          ? `/${location}${rootPaths.APARTMENTS}?city_id=${city_id}&district_id=${district_id}`
          : city_id
          ? `/${location}${rootPaths.APARTMENTS}?city_id=${city_id}`
          : district_id
          ? `/${location}${rootPaths.APARTMENTS}?district_id=${district_id}`
          : `/${location}${rootPaths.APARTMENTS}`,
      basePath: `/[${LOCATION}]${rootPaths.APARTMENTS}`,
    },

    {
      label: "Журнал",
      path: rootPaths.MAGAZINES,
      basePath: rootPaths.MAGAZINES,
    },
    // {
    //   label: "Tez_tez_beriladigan_savollar",
    //   path: rootPaths.FAQ,
    //   basePath: rootPaths.FAQ,
    // },
    {
      label: "Ипотечный калькулятор",
      path: rootPaths.MORTGAGE_CALCULATOR,
      basePath: rootPaths.MORTGAGE_CALCULATOR,
    },
    // {
    //   label: "EXPO 2024",
    //   path: "https://expo.uysot.uz/?utm_source=uysot_uz&utm_medium=site&utm_campaign=menu",
    // },
  ]
}
