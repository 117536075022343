import dayjs from "dayjs"
import queryString, { stringify } from "query-string"
import { ParsedUrlQuery } from "querystring"
import $api, { $newapi } from "../../../services/RequestService"
import { dayjsFormats } from "../../../utils/constants/dayjsFormats"
import { SIZE, YEAR } from "../../../utils/constants/queryParamsKeys"
import { isEmptyObject } from "../../../utils/helpers/isEmptyObject"
import { endpoints } from "../utils/constants/apis"
import { EXCLUDE_URL } from "../utils/constants/excludeUrl"
import { isNumberOdd } from "utils/helpers/isNumberOdd"

// complexes
export const fetchComplexes = (query: ParsedUrlQuery, complex_id?: number) => {
  let url = `/complex/view`
  let year

  if (complex_id) {
    url += `?${stringify(
      {
        [`complex_ids[]`]: complex_id,
      },
      { arrayFormat: "comma" }
    )}`

    url = queryString.exclude(url, [EXCLUDE_URL.location])
  }
  if (!isEmptyObject(query)) {
    if (query[YEAR] && Array.isArray(query[YEAR])) {
      year = dayjs
        .max([...(query[YEAR] as string[]).map((item) => dayjs(item))])
        ?.format(dayjsFormats.DEFAULT_DATE)
    }

    url += `?${stringify(
      {
        // sort_district_id: findIdAmongHyphens(query?.location as string),
        ...query,
        [SIZE]: 14,
        [YEAR]: year,
        // sort_value: "desc",
      },
      { arrayFormat: "comma" }
    )}`

    url = queryString.exclude(url, [EXCLUDE_URL.location])
  }

  // query

  return {
    url,
    fetch: async () => {
      const res = await $newapi.get(url)
      return res.data.data
    },
  }
}

// builders
export const fetchBuilders = async () => {
  const res = await $api.get(endpoints.BUILDERS_SELECT)
  return res.data
}

// classes
export const fetchClasses = async () => {
  const res = await $newapi.get(endpoints.CLASSES_SELECT)
  return res.data.data
}
