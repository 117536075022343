import { useQuery } from "@tanstack/react-query"
import { stringify } from "query-string"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import $api, { $newapi } from "../../../services/RequestService"
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer"
import { userReducerActions } from "../../../store/reducers/userReducer"
import { localStorageKeys } from "../../../utils/constants/localStorageKeys"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { NewApartmentCardModel } from "../../../utils/models/apartmentModel"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/apis"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  CityType,
  DistrictsType,
  MainRegionsModel,
} from "../utils/models/districtModel"
import { LocationReq, LocationRes } from "../utils/models/locationModel"
import { isBrowser } from "../../../utils/helpers/isBrowser"
import { useCheckHasToken } from "services/useCheckHasToken"
import { data } from "autoprefixer"

// define location
export function useDefineLocation(req: LocationReq) {
  const dispatch = useAppDispatch()
  const { userAddress } = useAppSelector((state) => state.userReducer)
  const { setUserAddress } = userReducerActions

  return useQuery<LocationRes, ErrorRes>(
    [queryKeys.DEFINE_LOCATION],
    async () => {
      const res = await $api.get(`${endpoints.LOCATION}?${stringify(req)}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        if (!userAddress?.city_id) {
          // @ts-ignore
          dispatch(setUserAddress(res))
        }
      },
      onError: errorHandler,
      enabled: !!req.city_name && !userAddress?.city_id,
    }
  )
}

// get city
export function useGetRegionCity() {
  return useQuery<CityType[], ErrorRes>(
    [queryKeys.CITY],
    async () => {
      const res = await $newapi.get(endpoints.CITY)
      return res.data.data
    },
    {
      onError: errorHandler,
      staleTime: Infinity,
    }
  )
}

export function useGetRegionDistricts(id?: number, step?: number) {
  return useQuery<DistrictsType[], ErrorRes>(
    [queryKeys.REGION_DISTRICTS, id],
    async () => {
      const res = await $newapi.get(
        `${endpoints.REGION_DISTRICTS}?city_id=${id}`
      )
      return res.data.data
    },
    {
      // onSuccess: (data) => {
      //   // console.log(data)
      // },
      onError: errorHandler,
      enabled: !!id && step === 1,
    }
  )
}

// check auth
export function useCheckAuth() {
  const anonymous_user_id = isBrowser()
    ? localStorage.getItem("anonymous_user_id")
    : ""
  return useQuery(
    [queryKeys.CHECK_AUTH],
    async () => {
      const res = await $newapi.get(
        `${endpoints.AUTH}?anonymous_user_id=${anonymous_user_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        if (res.data.anonymous_user_id) {
          localStorage.setItem(localStorageKeys.IS_AUTH, "false")
          localStorage.setItem("anonymous_user_id", res.data.anonymous_user_id)
        } else {
          localStorage.setItem(localStorageKeys.IS_AUTH, "true")
          localStorage.setItem(localStorageKeys.PHONE, res?.data?.phone)
        }
      },
      staleTime: Infinity,
      // enabled: isBrowser() && !localStorage.getItem(localStorageKeys.IS_AUTH),
    }
  )
}

// get favourites
export function useGetFavourites() {
  const dispatch = useAppDispatch()
  const { setApartments, setComplexes } = favouritesReducerActions

  return useQuery<{
    data: {
      complexes: NewResidentalComplexModel[]
      apartments: NewApartmentCardModel[]
    }
  }>(
    [queryKeys.FAVOURITES],
    async () => {
      const res = await $newapi.get(endpoints.FAVOURITES)
      return res.data
    },
    {
      onSuccess: (res) => {
        dispatch(setApartments(res?.data?.apartments))
        dispatch(setComplexes(res?.data?.complexes))
      },
      staleTime: Infinity,
      retry: false,
      // enabled: !!isAuth,
    }
  )
}
export function useGetMainRegions() {
  return useQuery<MainRegionsModel[]>({
    queryKey: [queryKeys.MAIN_REGIONS],
    queryFn: async () => {
      const res = await $newapi.get(`/city/select`)
      return res.data.data
    },
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
