import React from "react"

const ArrowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.7193 5.94L7.06596 10.2867C7.5793 10.8 8.4193 10.8 8.93263 10.2867L13.2793 5.94"
        stroke="#616161"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowIcon
