import React from "react"
import { useRouter } from "next/router"
import { layoutLang } from "utils/language/layout"
import { Collapse } from "antd"
import { useGetMainRegions } from "pages/main-layout/services/queries"
// import Link from "next/link"
import CustomLink from "common/custom-link"
import regions from "./regions.json"


import styles from "./footerFilter.module.scss"

const { Panel } = Collapse

const FooterFilter = () => {
  const { query, locale, pathname } = useRouter()
  const { data } = useGetMainRegions()


  const Cities = () => (
    <div className={styles.container_elements}>
      {regions?.map((item) => {
        return (
          <CustomLink
            href={
              pathname?.includes("kvartiri")
                ? `/gorod-tashkent/kvartiri?city_id=12&district_id=${item?.id}`
                : `/gorod-tashkent/novostroyki?city_id=12&district_id=${item?.id}`
            }
            key={item?.id}
            // prefetch={false}
          >
            <p
              className={`${
                item?.id === Number(query?.district_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.replace("tumani", "")
                : item?.name_ru?.replace("район", "")}
            </p>
          </CustomLink>
        )
      })}
    </div>
  )

  const Regions = () => (
    <div className={styles.container_elements}>
      {data?.map((item) => {
        return (
          <CustomLink
            href={
              pathname?.includes("kvartiri")
                ? `/${item?.url}/kvartiri?city_id=${item?.id}`
                : `/${item?.url}/novostroyki?city_id=${item?.id}`
            }
            key={item?.id}
            // prefetch={false}
          >
            <p
              className={`${
                item?.id === Number(query?.city_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.uz?.replace("viloyati", "")
                : item?.name?.ru?.replace("область", "")}
            </p>
          </CustomLink>
        )
      })}
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.container_body}>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_uylar"
                ]}
          </h3>
          <Cities />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_yangi_uylar"
                ]}
          </h3>
          <Regions />
        </div>
      </div>
      <div className={styles.container_bodyCollapse}>
        <Collapse
          expandIconPosition="end"
          bordered={false}
          defaultActiveKey={["1", "2"]}
        >
          <Panel
            className="bg-white mb-8"
            header={
              <h3 className="text-[#101828] text-xl font-semibold w-[93%]">
                {pathname?.includes("kvartiri")
                  ? layoutLang[locale as keyof typeof layoutLang][
                      "Toshkentdagi_yangi_kvartiralar"
                    ]
                  : layoutLang[locale as keyof typeof layoutLang][
                      "Toshkentdagi_yangi_uylar"
                    ]}
              </h3>
            }
            key={"1"}
          >
            <Cities />
          </Panel>
          <Panel
            className="bg-white"
            header={
              <h3 className="text-[#101828] text-xl font-semibold w-[93%]">
                {pathname?.includes("kvartiri")
                  ? layoutLang[locale as keyof typeof layoutLang][
                      "Butun_Ozbekiston_boylab_kvartiralar"
                    ]
                  : layoutLang[locale as keyof typeof layoutLang][
                      "Butun_Ozbekiston_boylab_yangi_uylar"
                    ]}
              </h3>
            }
            key={"2"}
          >
            <Regions />
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}

export default FooterFilter

