import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ConfigProvider } from "antd"
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl"
import type { AppProps } from "next/app"
import Head from "next/head"
import { Router, useRouter } from "next/router"
import Script from "next/script"
import NProgress from "nprogress"
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { SpeedInsights } from "@vercel/speed-insights/next"
import CallBackModal from "../src/pages/app/components/call-back-modal/CallBackModal"
import MainLayout from "../src/pages/main-layout/Index"
import { wrapper } from "../src/store/store"
import { colors } from "../src/utils/constants/colors"

import "antd/dist/antd.variable.min.css"
import "nprogress/nprogress.css"
import "swiper/css"
import "../src/styles/Common.scss"
import "../src/styles/Functions.scss"
import "../src/styles/Main.scss"
import { Provider } from "react-redux"
import AppWrapper from "../src/common/app-wrapper/AppWrapper"
import { Expo } from "../src/app"
import Quiz from "app/quiz/Quiz"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import NextHead from "common/next-head/NextHead"
import { UZ } from "utils/constants/locales"

type pageProps = {
  pageProps: {
    messages: AbstractIntlMessages | undefined
    dehydratedState: DehydratedState
  }
}

ConfigProvider.config({
  theme: {
    primaryColor: colors.PRIMARY_COLOR,
  },
})

NProgress.configure({
  showSpinner: false,
})

ReactGA.initialize("G-16JG6T4477")

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

function MyApp({ Component, pageProps, ...rest }: AppProps & pageProps) {
  const { store } = wrapper.useWrappedStore(rest)
  const router = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    // @ts-ignore
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string) // facebookPixelId
        ReactPixel.pageView()

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="facebook-domain-verification"
          content="y55ne5u8vg73mp2132esibsvq2xkq5"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />

        {/* <link
          rel="stylesheet"
          href="jquery.smartbanner.css"
          type="text/css"
          media="screen"
        ></link> */}
        <link rel="icon" href="/favicon.ico" />
        {/* <link rel="stylesheet" href="/dist/smartbanner.min.css" /> */}

        <meta
          name="google-site-verification"
          content="Yr7IJZKT7hINItRFnXtinouEzcBB5UOmwfwS4_Br3e8"
        />
        <link
          rel="apple-touch-icon"
          href="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
        />
        <link
          rel="app-touch-icon"
          sizes="76x76"
          href="https://drive.google.com/file/d/1lWpGF2xTV5RmBI1J1ctiK3JsnPvTKwsx/view?usp=sharing"
        />
        <link
          rel="app-touch-icon"
          sizes="120x120"
          href="https://drive.google.com/file/d/1xTLwmy83mD_fR-2KP0V3lp1xVAQKbTSJ/view?usp=sharing"
        />
        <link
          rel="app-touch-icon"
          sizes="152x152"
          href="https://drive.google.com/file/d/13n3mvNyu_rrBqFhfRgonqXVhSvsOfpdP/view?usp=sharing"
        />
        <link
          rel="app-touch-icon"
          sizes="180x180"
          href="https://drive.google.com/file/d/1EseSCBJBMQR9QoBIZHHBQ3Ee28JqGED8/view?usp=sharing"
        />
        <meta name="yandex-verification" content="7dc0f07b55c8f23d" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="mobile-web-app-capable" content="yes" />

        <meta
          name="apple-itunes-app"
          content="app-id=6451233026, app-argument=https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_link&itscg=30200"
        />

        <meta
          name="google-play-app"
          content="app-id=uz.uysot.market,  app-arguments=https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        />
        <meta name="smartbanner:title" content="Uysot" />
        <meta name="smartbanner:author" content="SOFTEX" />
        <meta name="smartbanner:button" content="Install App" />
        <meta name="smartbanner:price" content="FREE" />
        <meta
          name="smartbanner:icon-apple"
          content="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
        />
        <meta
          name="smartbanner:icon-google"
          content="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
        />
        <meta content="Uysot" property="og:site_name" />
        <meta name="smartbanner:enabled-platforms" content="android,ios" />
        <meta
          name="smartbanner:button-url-android"
          content="https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        />
        <meta
          name="smartbanner:button-url-apple"
          content="https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_link&itscg=30200"
        />
        {/* <script src="/dist/smartbanner.min.js"></script>
        <script src="https://smartappbanner.com/app.js" async /> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-L2716XRZ85"
        ></script>
        {router.pathname?.includes("expo") && (
          <>
            <title>UYSOT | EXPO</title>
          </>
        )}
      </Head>
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale={router.locale}
      >
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            {router.pathname?.includes("expo") ? (
              <Expo>
                <Component {...pageProps} />
              </Expo>
            ) : router?.pathname?.includes("quiz") ? (
              <Provider store={store}>
                <Quiz />
                <CallBackModal />
              </Provider>
            ) : (
              <Provider store={store}>
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
                <SpeedInsights />
                <CallBackModal />
                <ReactQueryDevtools initialIsOpen={false} />
                <AppWrapper />
              </Provider>
            )}
          </Hydrate>
        </QueryClientProvider>
      </NextIntlClientProvider>
      {/* Google analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-10943131584"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-10943131584');
        `}
      </Script>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2529825371829379"
        crossOrigin="anonymous"
      ></Script>
      <Script type="text/javascript">
        {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(92795399, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            ecommerce:"dataLayer"
        });`}
      </Script>
      {/* <script
        dangerouslySetInnerHTML={{
          __html: `
        window.replainSettings = { id: '3afc2340-2e40-4288-a9ab-9d78cb7e96b3' };
        (function(u){var s=document.createElement('script');s.async=true;s.src=u;
        var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
        })('https://widget.replain.cc/dist/client.js');
        `,
        }}
      ></script> */}
      {/* <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${fbq.FB_PIXEL_ID}&ev=PageView&noscript=1`}
          alt="Facebook script"
        />
      </noscript> */}
      {/* <noscript><div><img src="https://mc.yandex.ru/watch/92795399" style="position:absolute; left:-9999px;" alt="" /></div></noscript> */}
    </>
  )
}

export default MyApp
