import React from "react"

const DocumentsIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0833 7.49163H12.675C10.7 7.49163 9.09168 5.88329 9.09168 3.90829V1.49996C9.09168 1.04163 8.71668 0.666626 8.25834 0.666626H4.72501C2.15834 0.666626 0.0833435 2.33329 0.0833435 5.30829V12.6916C0.0833435 15.6666 2.15834 17.3333 4.72501 17.3333H11.275C13.8417 17.3333 15.9167 15.6666 15.9167 12.6916V8.32496C15.9167 7.86663 15.5417 7.49163 15.0833 7.49163ZM7.58334 13.7916H4.25001C3.90834 13.7916 3.62501 13.5083 3.62501 13.1666C3.62501 12.825 3.90834 12.5416 4.25001 12.5416H7.58334C7.92501 12.5416 8.20834 12.825 8.20834 13.1666C8.20834 13.5083 7.92501 13.7916 7.58334 13.7916ZM9.25001 10.4583H4.25001C3.90834 10.4583 3.62501 10.175 3.62501 9.83329C3.62501 9.49163 3.90834 9.20829 4.25001 9.20829H9.25001C9.59168 9.20829 9.87501 9.49163 9.87501 9.83329C9.87501 10.175 9.59168 10.4583 9.25001 10.4583Z"
        fill="white"
      />
    </svg>
  )
}

export default DocumentsIcon
