export const queryKeys = {
  DEFINE_LOCATION: "define-location",
  DISTRICTS: "districts",
  CHECK_AUTH: "check-auth",
  FAVOURITES: "favourites",
  REGION_DISTRICTS: "REGION_DISTRICTS",
  CITY: "CITY",
  MAIN_REGIONS: "MAIN_REGIONS",
  MAIN_CITIES: "MAIN_CITIES",
}
