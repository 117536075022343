import React, { useState, useEffect } from "react"
import { useTranslations } from "next-intl"
import { Button } from "antd"
import { isAndroid, isMobile } from "react-device-detect"
import IconImage from "../../assets/images/SecondLogoAppSuggesstion.png"
import Image from "next/image"
import LogoIcon from "assets/icons/LogoIcon"

import styles from "./Index.module.scss"
import CloseIcon from "assets/icons/CloseIcon"
import { layoutLang } from "utils/language/layout"
import { useRouter } from "next/router"

const hasOneDayPassed = () => {
  const lastCancelledTime = localStorage.getItem("appSuggestionCancelledAt")
  if (!lastCancelledTime) return false

  const now = new Date().getTime()
  const oneDayInMillis = 24 * 60 * 60 * 1000
  return now - parseInt(lastCancelledTime, 10) > oneDayInMillis
}

const AppSuggestion: React.FC = () => {
  const [isCanceled, setIsCanceled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const t = useTranslations("appSuggestion")
  const { locale } = useRouter()
  // new thing

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (hasOneDayPassed()) {
        setIsCanceled(false)
        localStorage.removeItem("appSuggestionCancelledAt")
      } else {
        // Initialize isCanceled state based on localStorage
        const lastCancelledTime = localStorage.getItem(
          "appSuggestionCancelledAt"
        )
        if (lastCancelledTime) {
          setIsCanceled(true)
        }
      }
      setIsLoading(false)
    }, 0)

    return () => clearTimeout(timeoutId) // Cleanup timeout
  }, [])

  const installApp = () => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    }
  }

  const cancelSmartbanner = () => {
    setIsCanceled(true)
    localStorage.setItem(
      "appSuggestionCancelledAt",
      new Date().getTime().toString()
    )
  }

  if (isLoading || isCanceled) return null

  if (isAndroid && isMobile && !isCanceled) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.container}>
          <div className={styles.logoAndInfoContainer}>
            <CloseIcon
              className={styles.cancelBtn}
              fill="rgba(255, 255, 255, 0.7)"
              onClick={cancelSmartbanner}
            />
            <div className={styles.logo}>
              <LogoIcon />
            </div>
            <div className={styles.info}>
              <h2>Uysot Marketplace</h2>
              <h5>{layoutLang[String(locale) as any]["Рынок_новых_домов"]}</h5>
            </div>
          </div>
          <Button
            className={styles.installAppBtn}
            type="primary"
            color="green"
            onClick={installApp}
          >
            {layoutLang[String(locale) as any]["Скачать"]}
          </Button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default AppSuggestion
